export const MigrationFriesData = [
  {
    id: "33",
    category: "MIGRATION NEWS",
    title: "Are you eligible to become a British citizen?",
    date: "Opeyemi Balogun / Oct 30, 2024",
    desc: "There are different ways to apply for British citizenship (or ‘naturalisation’) based on your circumstances. If you’re eligible in more than one way you can choose which way to apply. If you’ve applied for citizenship,",
    link: "https://economictimes.indiatimes.com/nri/migrate/are-you-eligible-to-become-a-british-citizen-check-here/articleshow/114683603.cms",
  },
  {
    id: "32",
    category: "MIGRATION NEWS",
    title:
      "Canada raises wage requirements for high-wage stream of Temporary Foreign Worker Program",
    date: "Opeyemi Balogun / Oct 30, 2024",
    desc: " Canada has announced more changes to the Temporary Foreign Worker Program (TFWP), including raising the minimum hourly wage requirements for the high-wage stream of the TFWP.",
    link: " https://economictimes.indiatimes.com/nri/work/canada-raises-wage-requirements-for-high-wage-stream-of-temporary-foreign-worker-program/articleshow/114473588.cms",
  },
  {
    id: "31",
    category: "MIGRATION NEWS",
    title:
      "Canada's new immigration plan: Key numbers to know if you're planning the move",
    date: "Opeyemi Balogun / Oct 30, 2024",
    desc: " Canada's government has unveiled a new immigration plan for 2025–2027 aimed at balancing population growth with economic and social stability.",
    link: " https://economictimes.indiatimes.com/nri/migrate/canadas-new-immigration-plan-key-numbers-to-know-if-youre-planning-the-move/articleshow/114572712.cms",
  },

  {
    id: "30",
    category: "MIGRATION NEWS",
    title:
      "Canada announces new Post-Graduation Work Permit rules, effective November 2024",
    date: "Opeyemi Balogun / October 16, 2021",
    desc: "Immigration, Refugees and Citizenship Canada (IRCC) has announced new rules affecting eligibility for the Post-Graduation Work Permit (PGWP).",
    link: "https://economictimes.indiatimes.com/nri/work/canada-announces-new-post-graduation-work-permit-rules-effective-november-2024/articleshow/114073202.cms",
  },
  {
    id: "29",
    category: "MIGRATION NEWS",
    title: "9 countries that allow dual citizenships. Does India?",
    date: "Opeyemi Balogun / September 17, 2021",
    desc: "Offering individuals the flexibility to maintain ties to multiple nations.However, the rules and eligibility criteria can vary significantly. Here's an overview of some countries that permit dual citizenship.",
    link: "https://economictimes.indiatimes.com/nri/migrate/9-countries-that-allow-dual-citizenships-does-india/best-of-both-worlds/slideshow/113119179.cms",
  },
  {
    id: "28",
    category: "MIGRATION NEWS",
    title:
      "Green Card for Geniuses: Everything you need to know about the EB-1A visa",
    date: "Opeyemi Balogun / September 17, 2021",
    desc: " The EB-1A visa category is for individuals who particularly demonstrate extraordinary abilities in their respective fields. ",
    link: " https://economictimes.indiatimes.com/nri/migrate/green-card-for-geniuses-everything-you-need-to-know-about-the-eb-1a-visa/articleshow/112221889.cms",
  },
  {
    id: "27",
    category: "MIGRATION NEWS",
    title: "What is an F-1 visa? Here is everything you need to know",
    date: "Opeyemi Balogun / September 17, 2021",
    desc: "F-1 visas are a type of non-immigrant student visa that permits foreigners to study (academic or a language training program) in the United States. F-1 students are expected to maintain a complete course of study. ",
    link: "https://economictimes.indiatimes.com/nri/study/what-is-an-f-1-visa-here-is-everything-you-need-to-know/articleshow/79734956.cms",
  },
  {
    id: "26",
    category: "MIGRATION NEWS",
    title:
      "Explore the New Brunswick Provincial Nominee Program: Your pathway to Canadian PR",
    date: "Opeyemi Balogun / August 16, 2024",
    desc: "The New Brunswick Provincial Nominee Program (NBPNP) is an immigration pathway designed to attract skilled workers to live, …",
    link: "https://economictimes.indiatimes.com/nri/migrate/explore-the-new-brunswick-provincial-nominee-program-your-pathway-to-canadian-pr/articleshow/112518698.cms",
  },
  {
    id: "25",
    category: "MIGRATION NEWS",
    title:
      "Singapore eases path to permanent residency for international students",
    date: "Opeyemi Balogun / August 16, 2024",
    desc: " International students in Singapore can now apply for permanent residency (PR) as soon as they pass at least one national exam, such as the PSLE or GCE ‘N’/’O’/’A’ levels. …",
    link: " https://economictimes.indiatimes.com/nri/migrate/singapore-eases-path-to-permanent-residency-for-international-students/articleshow/112514697.cms",
  },
  {
    id: "24",
    category: "MIGRATION NEWS",
    title: "Immigrants are becoming US citizens at fastest clip in years",
    date: "Opeyemi Balogun / August 16, 2024",
    desc: "The federal government is processing citizenship requests at the fastest clip in a decade, moving rapidly through a backlog that built up during the Trump …",
    link: "https://economictimes.indiatimes.com/nri/migrate/immigrants-are-becoming-us-citizens-at-fastest-clip-in-years/articleshow/112513499.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst",
  },
  {
    id: "23",
    category: "MIGRATION NEWS",
    title:
      " New Zealand tightens rules for foreign workers looking to sponsor dependants",
    date: "Opeyemi Balogun / July 9, 2024",
    desc: "New Zealand has announced new immigration rules that won't let some foreign workers sponsor their dependents for work, visitor, or student visas …",
    link: "https://economictimes.indiatimes.com/nri/migrate/new-zealand-tightens-rules-for-foreign-workers-looking-to-sponsor-dependants/articleshow/111336419.cms",
  },
  {
    id: "22",
    category: "MIGRATION NEWS",
    title:
      " UK immigration: Will Starmer make it more difficult for you to move to Britain?",
    date: "Opeyemi Balogun / July 9, 2024",
    desc: " The United Kingdom has a new Prime Minister, and Labour Party's Keir Starmer may tighten immigration policies, putting more border checks…",
    link: "https://economictimes.indiatimes.com/nri/migrate/uk-has-a-new-prime-minister-how-immigration-may-change-under-the-new-labour-government/articleshow/111528228.cms",
  },
  {
    id: "21",
    category: "MIGRATION NEWS",
    title:
      " Canada's Quebec introduces cap on family sponsorship applications ",
    date: "Opeyemi Balogun / July 9, 2024",
    desc: "Quebec has introduced a limit on family sponsorship applications which will be effective till June 25, 2026. The cap imposes a limit of 13,000 applications …",
    link: "https://economictimes.indiatimes.com/nri/migrate/canadas-quebec-introduces-cap-on-family-sponsorship-applications/articleshow/111420331.cms",
  },
  {
    id: "20",
    category: "MIGRATION NEWS",
    title:
      "Germany: The top choice for obtaining multiple-entry Schengen Visa this year",
    date: "Opeyemi Balogun / June 25, 2024",
    desc: "If you are planning on obtaining a short-term visa that will help you to enter the Schengen Area countries more than once, you should consider applying for a German Schengen...",
    link: "https://economictimes.indiatimes.com/nri/visit/germany-the-top-choice-for-obtaining-multiple-entry-schengen-visa-this-year/articleshow/111222543.cms",
  },
  {
    id: "19",
    category: "MIGRATION NEWS",
    title:
      "Comprehensive guide to the L-2 visa for dependents of L-1 visa holders",
    date: "Opeyemi Balogun / June 25, 2024",
    desc: "If you are the spouse or child of an L-1 visa holder, you may be eligible to move to the US on an L-2 visa...",
    link: "https://economictimes.indiatimes.com/nri/work/comprehensive-guide-to-the-l-2-visa-for-dependents-of-l-1-visa-holders/articleshow/111222538.cms",
  },
  {
    id: "18",
    category: "MIGRATION NEWS",
    title: "Green Cards for Grads: Trump’s new vote magnet?",
    date: "Opeyemi Balogun / June 25, 2024",
    desc: "Imagine you’ve just tossed your graduation cap in the air at a top US university, and then you receive a life-changing announcement from Donald Trump...",
    link: "https://economictimes.indiatimes.com/nri/migrate/green-cards-for-grads-trumps-new-vote-magnet/articleshow/111190352.cms",
  },
  {
    id: "17",
    category: "MIGRATION NEWS",
    title: "UK announces plans to introduce new caps on work & family visas",
    date: "Opeyemi Balogun / June 10, 2024",
    desc: "UK’s ruling Conservative party unveiled plans on Monday to implement new annual caps on work and family visas in an",
    link: "https://blog1.wevesti.com/uk-announces-plans-to-introduce-new-caps-on-work-family-visas/",
  },
  {
    id: "16",
    category: "MIGRATION NEWS",
    title:
      "Hungary to relaunch Golden Visa as Guest Investor Program on July 1",
    date: "Opeyemi Balogun / June 10, 2024",
    desc: "Hungary has reintroduced its Golden Visa Program, now called the Guest Investor Program (GIP), effective from July 1. This program,",
    link: "https://blog1.wevesti.com/hungary-to-relaunch-golden-visa-as-guest-investor-program-on-july-1/",
  },
  {
    id: "15",
    category: "MIGRATION NEWS",
    title:
      "Portugal to use golden visa scheme to help migrants, build affordable homes",
    date: "Opeyemi Balogun / June 10, 2024",
    desc: "Portugal plans to adapt its golden visa scheme to allow wealthy foreigners seeking residency rights to invest in affordable housing […]",
    link: "https://blog1.wevesti.com/portugal-to-use-golden-visa-scheme-to-help-migrants-build-affordable-homes/",
  },
  {
    id: "14",
    category: "JOBS",
    title: "NURSE (SPONSORSHIP AVAILABLE)",
    img: "https://blog1.wevesti.com/wp-content/uploads/2024/06/nurse-portrait-hospital-1.jpg",
    date: "Opeyemi Balogun / June 6, 2024",
    desc: "Job Ref: CAI1816 Branch:  Kenton Manor Care Home Salary/Benefits: £20.00ph + benefits package Contract type: Permanent Hours: Full Time Hours",
    link: "https://blog1.wevesti.com/nurse-sponsorship-available/",
  },
  {
    id: "13",
    category: "JOBS",
    title: "Division Chief, Interventional Radiology",
    img: "https://blog1.wevesti.com/wp-content/uploads/2024/06/medium-shot-man-holding-radiography-1-1.jpg",
    date: "Opeyemi Balogun / June 6, 2024",
    desc: "Springfield, MA Easy Apply Baystate Health, western Massachusetts’ premier healthcare provider and home to the University of Massachusetts Chan Medical",
    link: "https://blog1.wevesti.com/division-chief-interventional-radiology/",
  },
  {
    id: "12",
    category: "JOBS",
    title: "Data Analyst – VISA SPONSORSHIP PROVIDED",
    img: "https://blog1.wevesti.com/wp-content/uploads/2024/06/businesswoman-using-analytics-data-kpi-dashboard-1.jpg",
    date: "Opeyemi Balogun / June 6, 2024",
    desc: "London, England Easy Apply Data Analyst (Visa Sponsorship Available) | SaaS Scale-Up | £40,000 – £50,000 per annum | London",
    link: "https://blog1.wevesti.com/data-analyst-visa-sponsorship-provided/",
  },
  {
    id: "11",
    category: "JOBS",
    title: "Deputy Manager – Home Care (UK visa sponsorship)",
    img: "https://blog1.wevesti.com/wp-content/uploads/2024/06/portrait-smiling-person-everyday-life-1.jpg",
    date: "Opeyemi Balogun / June 6, 2024",
    desc: "Birmingham, England About Us: Just Call 4 Care Services Ltd. is a leading provider of home care services across the […]",
    link: "https://blog1.wevesti.com/deputy-manager-home-care-uk-visa-sponsorship/",
  },
  {
    id: "10",
    category: "JOBS",
    title:
      "Podiatrist (Work car, visa sponsorship, $110k+ salary + other great perks)",
    img: "https://blog1.wevesti.com/wp-content/uploads/2024/05/nurse-wearing-scrubs-while-working-clinic-1.jpg",
    date: "Opeyemi Balogun / May 22, 2024",
    desc: "Melbourne VIC Physiotherapy, OT & Rehabilitation (Healthcare & Medical) Full time $100,000 – $135,000 per year About usServicing Berwick and […]",
    link: "https://blog1.wevesti.com/podiatrist-work-car-visa-sponsorship-110k-salary-other-great-perks/",
  },
  {
    id: "9",
    category: "JOBS",
    title: "Patient Access Representative",
    img: "https://blog1.wevesti.com/wp-content/uploads/2024/05/wheelchair-user-doctor-analyzing-radiography-results-medical-consultation-cabinet-medic-explaining-x-ray-scan-diagnosis-woman-with-chronic-disability-impairment-healthcare-checkup-1.jpg",
    date: "Opeyemi Balogun / May 22, 2024",
    desc: "Pay Range: $25.00 – $29.35 SEARHC is a non-profit health consortium which serves the health interests of the residents of […]",
    link: "https://blog1.wevesti.com/patient-access-representative/",
  },

  {
    id: "8",
    category: "JOBS",
    title: "Registered Nurse – LTC",
    img: "https://blog1.wevesti.com/wp-content/uploads/2024/05/two-female-nurses-working-clinic-scrubs-1.jpg",
    date: "Opeyemi Balogun / May 22, 2024",
    desc: "Pay Range: $41.83 – $53.45 SEARHC is a non-profit health consortium which serves the health interests of the residents of […]",
    link: "https://blog1.wevesti.com/registered-nurse-ltc/",
  },
  {
    id: "7",
    category: "MIGRATION NEWS",
    title: "UAE launches 10-year Blue Residency visa; here are all the details",
    date: "Opeyemi / May 22, 2024",
    desc: "The United Arab Emirates has launched a 10-year Blue Residency visa for individuals who have made ‘exceptional efforts and contributions",
    link: "https://economictimes.indiatimes.com/nri/migrate/uae-launches-10-year-blue-residency-visa-here-are-all-the-details/articleshow/110179611.cms",
  },
  {
    id: "6",
    category: "MIGRATION NEWS",
    title:
      "US Visa Bulletin for June 2024: No movement in key Green Card deadlines",
    date: "Opeyemi / May 22, 2024",
    desc: "US Green Card applications: The U.S. State Department’s recent release of the June Visa Bulletin brings both updates and limitations",
    link: "https://economictimes.indiatimes.com/nri/migrate/us-visa-bulletin-for-june-2024-no-movement-in-key-green-card-deadlines/articleshow/110073378.cms",
  },
  {
    id: "5",
    category: "MIGRATION NEWS",
    title: "Can a foreigner on a work visa in Dubai switch to UAE Golden Visa?",
    date: "Opeyemi / May 22, 2024",
    desc: "If you are currently employed by a mainland Dubai-based company and plan to purchase a house to qualify for the UAE Golden Visa, you might be […]",
    link: "https://economictimes.indiatimes.com/nri/migrate/can-a-foreigner-on-a-work-visa-in-dubai-switch-to-uae-golden-visa/articleshow/110140732.cms",
  },
  {
    id: "4",
    category: "SCHOLARSHIPS",
    title: "Vice Chancellor’s Elite Scholarship",
    img: "https://blog1.wevesti.com/wp-content/uploads/2024/05/Screen-Shot-2024-05-22-at-1.21.35-PM.jpg",
    date: "Opeyemi Balogun / May 20, 2024",
    desc: "Overview Our prestigious Vice Chancellor’s Elite Scholarships are awarded each year to a small group of outstanding applicants. Vice Chancellor’s",
    link: "https://blog1.wevesti.com/vice-chancellors-elite-scholarship/",
  },
  {
    id: "3",
    category: "SCHOLARSHIPS",
    title: "A*STAR GRADUATE SCHOLARSHIP",
    img: "https://blog1.wevesti.com/wp-content/uploads/2024/05/young-african-woman-smiling-graduation-gown-generated-by-ai-1.jpg",
    date: "Opeyemi Balogun / May 20, 2024",
    desc: "The A*STAR Graduate Scholarship (AGS) will finance up to four years of your PhD or EngD studies at an autonomous",
    link: "https://blog1.wevesti.com/astar-graduate-scholarship/",
  },
  {
    id: "2",
    category: "SCHOLARSHIPS",
    title: "Oxford Centre for Islamic Studies (OCIS) Scholarships",
    img: "https://blog1.wevesti.com/wp-content/uploads/2024/05/Screen-Shot-2024-05-22-at-1.10.18-PM.png",
    date: "Opeyemi Balogun / May 20, 2024",
    desc: "About The Oxford Centre for Islamic Studies is an institution for the advanced study of Islam and the Muslim world.",
    link: "https://blog1.wevesti.com/oxford-centre-for-islamic-studies-ocis-scholarships/",
  },
  {
    id: "1",
    category: "SCHOLARSHIPS",
    title: "The IMPACT Scholarship application form (for 2024/25 intake)",
    img: "https://blog1.wevesti.com/wp-content/uploads/2024/05/couple-making-plan-using-tablet-redecorate-house-1.jpg",
    date: "Opeyemi Balogun / May 20, 2024",
    desc: "Overview Preventing and redressing the impact of inequalities is a core philosophy of the UCL Master of Public Health (MPH). To reflect this, we are offering two",
    link: "https://blog1.wevesti.com/the-impact-scholarship-application-form-for-2024-25-intake/",
  },
  // Demilade

  // Demilade
];
