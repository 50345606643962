import Atti from "../assets/images/Atti.jpeg";
import Ajayi from "../assets/images/Ajayi.jpeg";
import Ojji from "../assets/images/Ojji.jpeg";
import Yewande from "../assets/images/Yewandee.jpeg";
import avatar from "../assets/images/dummy-avatar.png";
import Dami from "../assets/images/demo.png";
import Tutti from "../assets/images/tut.png";
import Olayide from "../assets/images/yide.png";
import Odunuga from "../assets/images/oluw.png";
import Arinze from "../assets/images/Arinze.png";
import Abdu from "../assets/images/abdr.png";
// import avatar from "../assets/images/dummy-avatar.png";

export const Testimony = [
  {
    image: Dami,
    name: "Damola Oni",
    date: "United States • Wednesday, 29 of August 2021 by 21:33pm",
    testimony:
      "After the event of 20-10-20, I knew the country wasn’t for me anymore. Thankfully, the team at Vesti was a step ahead of me, I attended a 2 hour webinar organized by Vesti on Saturday, 24th of October 2020 and it changed my orientation forever. Today, I am in the United States of America, and none of it would be possible without “Japa webinar 1.0” and the great work being done by the team at Vesti.",
    background: "#F7FFF3",
  },
  {
    image: Tutti,
    name: "Tutti Uwanikone",
    date: "France • Wednesday, 29 of August 2021 by 21:33pm",
    background: "#F6F7FF",

    testimony:
      "Still feels like a dream that I made it to France for my Masters in Data Science and Artificial Intelligence in Business. Dreams come through with Vesti, take action today!",
  },
  {
    image: Olayide,
    name: "Olayide Olumeko",
    date: "United Kingdom • Wednesday, 29 of August 2021 by 21:33pm",
    background: "#FBF3FF",

    testimony:
      "I came about Mr. Amusan's post on Linkedin I adopted those series and became an addicted follower, read all the post, emails, Youtube channel, all Japa series, I actioned on the webinars put together by the Co-Founder. Then came 2021 now a reality, my entire family and myself are already in the UK. I can't wait to see this become the next slogan on CNN",
  },
  {
    image: Odunuga,
    name: "Oluwaloni Odunuga ",
    date: "United Kingdom • Wednesday, 30 of August 2021 by 17:28pm",
    background: "#F6F7FF",

    testimony:
      "Arriving in the UK is a dream come true. Never knew it could be seamless. All thanks to the requirements information and support I got from ",
  },
  {
    image: Arinze,
    name: "Arinze N",
    date: "United Kingdom • Wednesday, 29 of August 2021 by 21:33pm",
    background: "#FBF3FF",

    testimony:
      "I remember joining the very first JAPA webinar. After it I was fired up and determined to find ways possible to join my wife in the uk. Now with my wife in the Uk and life has been amazing ever since.",
  },
  // {
  //   image: avatar,
  //   name: " Mr Iyoriobhe O.",
  //   date: "Canada • Wednesday, 29 of August 2021 by 21:33pm",
  //   testimony:
  //     "Before I joined the Vesti Telegram group, I had no passport, Today, I have not only gotten my passport but owing to the catalytic effort of the Japa 1.0 , I just got my Canada study Visa approved. I want to thank you very much for all you do.",
  // },
  {
    image: Abdu,
    name: "Abdur-Rahman Ridwan",
    date: "United Kingdom • Wednesday, 29 of August 2021 by 21:33pm",
    background: "#F7FFF3",

    testimony:
      '"Vesti was instrumental in my educational Immigration journey to the UK". It is a reliable platform that empowers individuals to realize different Immigration ambitions through its tailored solutions practically. More than a platform, it is a dependable community that drives you to your Immigration dreams from point A - Z with you still in charge.',
  },
];

export const OVTestimony = [
  {
    image: Atti,
    name: "Bestie Atti",
    date: "Founder & CEO, CorporateBestie Virginia Beach",
    testimony:
      "Working with Vesti to migrate to the US via the EB-1 route was very excellent. During our first meeting and after reviewing my case, they suggested the most effective and efficient pathway for my family, business and I. The team was also very responsive, transparent, kind and supportive throughout the process. I highly recommend using Vesti.",
    backgroundColor: "#F7FFF3",
  },
  {
    image: Ojji,
    name: "Stephen Ojji",
    date: "Founder and CEO, NoCopyCopy Houston, Texas",
    testimony:
      "The team at Vesti were very professional and showed great support offering sevices in helping me get the O-1 Visa. Kudos to Olu and the team!",
    backgroundColor: "#f6f7ff",
  },
  {
    image: Ajayi,
    name: "Adeshina Ajayi",
    date: "Digital Focus LLC Chicago, Illinois",
    testimony:
      "Few months back, I got on a clarity call with Vesti team where he introduced me to the US entrepreneur visa (0-1) and how I can be qualified for it. After the call, I got on board and the experienced attorney team at VESTI worked tirelessly on my case to ensure approval. Right now, I have my US 0-1 visa which covers my spouse and kids.",
    backgroundColor: "#fbf3ff",
  },
  {
    image: Yewande,
    name: "Yewande Adeola Adekoya",
    subtitle: "FNIM, FIMC, ACIB",
    date: "Founder/ CEO Ruackel LLC (USA)",
    testimony:
      "I had a long-standing dream of starting a global business in the United States. Vesti made this dream a reality within six months through the O-1 visa process, in a professional and efficient manner. I am truly grateful for their support.",
    backgroundColor: "#fbf3ff",
  },
  {
    image: avatar,
    name: "Aaron T Aaron",
    testimony:
      "This is a shout out to an amazing company Vesti, who  provided an exceptional service in securing my 0-1 visa for the US, guiding me seamlessly from the initial setup of my company set up  to the successful completion of securing the visa. Their expertise and attention to detail made the journey remarkably smooth, and their dedicated support  i personally received from Sola, Damola,, Abimbola, Bunmi and Martins ensured every aspect of the visa application was handled with precision. I highly recommend Vesti for their outstanding commitment to facilitating a stress-free experience in navigating the complexities of the visa process and business establishment in the USA. I highly do reccomend VESTI.",
    backgroundColor: "#f6f7ff",
  },
];

export const VisaClarityCallPricing = [
  {
    title: "One on One Call ",
    description:
      "Meet one-on-one with a Vesti team member. You’ll get to receive Vesti’s annual community membership worth $299.",
    price: "$99.99",
    backgroundColor: "#F7FFF3",
    textColor: "#14290A",
    priceColor: "#67A948",
  },
  {
    title: "Pre-recorded Call",
    description:
      "Gain valuable insights from a pre-recorded session with a Vesti expert, offering you expert guidance and support",
    price: "$39.99",
    backgroundColor: "#FBF3FF",
    textColor: "#3F1951",
    priceColor: "#A35CC5",
  },
  {
    title: "Miai Migration Assistant AI",
    description:
      "Gain valuable insights from a pre-recorded session with our Migration Assistant AI, offering you guidance and support.",
    price: "$19.99",
    backgroundColor: "#F6F8FF",
    textColor: "#060E42",
    priceColor: "#5261BC",
  },
];
