import React from "react";
import hundredImg from "../../assets/Partnership-Img/hundredPlus.png";
import { Link as AnchorLink } from "react-scroll";

export const PartNum = () => {
  return (
    <div className=" md:flex md:flex-col md:items-center xl:flex xl:flex-col xl:items-center xlg:flex xlg:flex-col xlg:items-center mb-[5%] px-4">
      <div className=" md:flex md:items-center md:justify-between xl:flex xl:items-center xl:justify-between xlg:flex xlg:items-center xlg:justify-between rounded-md bg-[#060E42]  md:w-[96%] xl:w-[91%] xlg:w-[90%] p-8">
        <div className="sm:flex sm:items-center sm:gap-10 md:flex md:items-center md:gap-10 xl:flex xl:items-center xl:gap-10 xlg:flex xlg:items-center xlg:gap-10 w-[100%] md:w-[90%] ">
          <img
            src={hundredImg}
            alt=""
            className=" w-[20%] sm:w-[14%] md:w-[16%] xl:w-[10%] xlg:w-[10%]"
          />
          <p className="text-[#FFFFFF] text-[20px] w-[100%]  my-4">
            We have successfully processed over 100 visas for leading startups
            and founders to date, and the number continues to grow.
          </p>
        </div>

        <div></div>
        {/* <AnchorLink
              spy={true}
              smooth={true}
              to="referAndPartnerSection"
              className='rounded-md text-[#FFFFFF] bg-vestigreen px-10 sm:px-14 py-2 md:py-3 md:px-10 xl:py-3 xl:px-10 xlg:py-3 xlg:px-10 my-4 cursor-pointer'>
              Partner with Us</AnchorLink> */}
      </div>
    </div>
  );
};
